import { Popup } from "react-leaflet"
import { Button } from "react-bootstrap";
import { violationsFixations } from "../helper";
import React from "react"
import { getProblems } from "../helper";
import { useContext } from "react";
import { CurrentComplexContext } from "../../../context";
import '../../../styles/MarkerPopup.css'
import '../../../styles/MarkerPopup_2x.css'


const MarkerPopup = props => {
    const { violations, fixations } = violationsFixations(props.complex);
    const { setCurrentComplexPayload } = useContext(CurrentComplexContext)


    
    const infoClick = () => {
        setCurrentComplexPayload({ modal: true, class: 'crossroad', complex: props.complex })
    }


    var problems = getProblems(props.complex)
    props.complex.child.map(host => {
        problems = [...getProblems(host), ...problems]
    })
    problems = problems.sort((a,b) => {
        return a[1].split('/')[1].localeCompare(b[1].split('/')[1])
    })
    // props.complex.vsc.map(vsc => {
    //     problems = [...getProblems(vsc), ...problems]
    // })
    // console.log(`fixations ${props.complex.name}`, fixations)
    return (
        <Popup maxWidth={600}>
            {/* <Container fluid>
            <Form>
                <Row className='popup_header' >
                    <span style={{ fontWeight: 600 }}>{props.complex.name} </span>
                </Row>
                <hr style={{ margin: '5px' }} />
                <Row className='popup_body'>
                    <Col sm={4} className="popup_body_badge popup_body_badge_coordinates">{props.complex.inventory.location_lat}, {props.complex.inventory.location_lon}</Col>
                    <Col sm={4} className="popup_body_badge popup_body_badge_date">{(new Date()).toLocaleString('ru')}</Col>
                </Row>
                <hr style={{ margin: '5px' }} />
                <Row className='popup_body'>
                    <Col sm={3} className="popup_body_badge popup_body_badge_fixations">Проездов {fixations}</Col>
                    <Col sm={3} className="popup_body_badge popup_body_badge_violations">Нарушений {violations}</Col>
                </Row>
                <hr style={{ margin: '5px' }} />
                {
                problems.length === 0
                    ?
                    <Row className='popup_body'>
                        <Col className="popup_body_badge popup_body_badge_alerts_good">Работа в штатном режиме</Col>
                        </Row>
                    : problems.map(element => {
                        return (
                            <Row md className='popup_body' key={element[0]}>
                                <Col xs={3} sm className="popup_body_badge popup_body_badge_alerts_host">{element[1].split('/')[1].replace('(', ' (')}</Col>
                                <Col xs={6} sm className="popup_body_badge popup_body_badge_alerts_problem" style={{ color: element[4] }}>{element[2]}</Col>
                                <Col xs={1} sm className="popup_body_badge popup_body_badge_alerts_duration">{element[3]}</Col>
                            </Row>

                        )
                    })
                }
                <Row className='popup_footer'>
                    <Button className="popup_footer_button" size="sm" variant="primary" onClick={infoClick}> Подробно </Button>
                </Row>
            </Form>
            </Container> */}
            <div className='popup_header'>
                <span style={{ fontWeight: 600 }}>{props.complex.name} </span>
            </div>
            <hr style={{ margin: '5px' }} />
            <div className='popup_body'>
                <span className="popup_body_badge popup_body_badge_coordinates">{props.complex.inventory.location_lat}, {props.complex.inventory.location_lon}</span>
                <span className="popup_body_badge popup_body_badge_date">{(new Date()).toLocaleString('ru')}</span>
                
                <br />
            </div>
            <hr style={{ margin: '5px' }} />
            <div className='popup_body'>
                <span className="popup_body_badge popup_body_badge_fixations">Проездов</span>
                <span className="popup_body_badge popup_body_badge_fixations">{fixations}</span>
                <span className="popup_body_badge popup_body_badge_violations">{violations}</span> 
                <span className="popup_body_badge popup_body_badge_violations">Нарушений</span>
                <br />
            </div>
            <hr style={{ margin: '5px' }} />
            {
                problems.length === 0
                    ?
                    <div className='popup_body'>
                        <span className="popup_body_badge popup_body_badge_alerts_good">Работа в штатном режиме</span>
                    </div>
                    : problems.map(element => {
                        return (
                            <div className='popup_body' key={element[0]}>
                                <span className="popup_body_badge popup_body_badge_alerts_host">{element[1].split('/')[1]}</span>
                                <span className="popup_body_badge popup_body_badge_alerts_problem" style={{ color: element[4] }}>{element[2]}: {element[3]}</span>
                            </div>

                        )
                    })
            }
            <hr style={{ margin: '5px' }} />
            <div className='popup_footer'>
                <Button className="popup_footer_button" size="sm" variant="primary" onClick={infoClick}> Подробно </Button>
            </div>
        </Popup>
    )
}


export default MarkerPopup;
