const TooltipContent = props => {
    const fixations = props.complex.items.find(item => item.key_ === 'fixations')
    if (fixations) {
        return (
            <div>
                {fixations.lastvalue}
            </div>
        )
    }

}

export default TooltipContent;





