import { Accordion } from 'react-bootstrap';
const ComplexData = props => {


    var types_names = new Set()
    var types = {}
    props.complex.items.map(item => {
        var t
        t = item.name.indexOf('.') !== -1 ? item.name.split('.')[0] : t = 'Общие'
        types_names.add(t)
        types[t] = !types[t] ? [item] : [item, ...types[t]]
    })
    types_names = Array.from(types_names)
    return (
        <Accordion defaultActiveKey={types_names} alwaysOpen>

            {
                types_names.map(type => {
                    return (
                        <Accordion.Item key={type} eventKey={type}>
                            <Accordion.Header as={'h1'}>{type}</Accordion.Header>
                            <Accordion.Body>
                               
                                {
                                    types[type].map(item => {
                                        if (item.status === "0") {
                                            return (<li key={`${item.key_}`}><span> {item.name.indexOf('.') !== -1 ? item.name.split('.')[1]: item.name} : {item.lastvalue} {item.units}</span></li>)
                                        }
                                    })
                                }
                                                       
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })
            }
        </Accordion>
    )


    // return (
    //     <div>
    //         <div className="trigger-list">
    //             {
    //                 props.complex.triggers.map(trigger => {
    //                     return (
    //                         <div className="trigger-list__item" key={`${trigger.triggerid}_${props.complex.hostid}`}>
    //                             <div>{triggerInfo(trigger).name}</div>
    //                             <div>{triggerInfo(trigger).duration}</div>
    //                         </div>

    //                     )
    //                 })
    //             }
    //         </div>
    //         <div className="params-list">
    //             {
    //                 itemsNormalizer(props.complex).items.map(item => {
    //                     return (
    //                         <div className="params-list__item" key={`${item.key_}_${props.complex.hostid}`}>
    //                             <div>{item.name}</div>
    //                             <div>{item.lastvalue}&nbsp;{item.units}</div>
    //                         </div>

    //                     )
    //                 })
    //             }

    //         </div>
    //     </div>
    // )


}

export default ComplexData