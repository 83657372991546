import React, { useContext } from "react";
import { Marker } from "react-leaflet";
import { getIcon, getIconMarkerCluster, getTriggerMaxPriority } from "../helper";
import "leaflet/dist/leaflet.css";
import "leaflet-extra-markers";
import "font-awesome/css/font-awesome.css";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import MarkerPopup from "./MarkerPopup";
import MarkerTooltip from "./MarkerTooltip";
import { ComplexesFilterContext } from "../../../context";
import MarkerClusterGroup from 'react-leaflet-cluster'

export const MarkersSpeed = props => {
    const { complexesFilterPayload } = useContext(ComplexesFilterContext)
    // const clusters = useRef(null)
    // const {test, setTest} = useState(0)

    // useEffect(() => {
    //     console.log('useeffect')
    //     if (clusters.current) {
    //         const layers = clusters.current.getLayers()
    //         clusters.current.removeLayers(layers)
    //         clusters.current.addLayers(layers)
    //         // clusters.current.refreshClusters()
    //     }
    // }, [props])

    const createClusterCustomIconForSpeed = (cluster) => {
        // console.log('createClusterCustomIconForSpeed')
        var triggerMaxPriority = 0
        cluster.getAllChildMarkers().map(child => {
            if (child.options.trigger > triggerMaxPriority) {
                triggerMaxPriority = child.options.trigger
            }   
        })
        return getIconMarkerCluster(cluster.getAllChildMarkers().length, triggerMaxPriority)
    };
    
    if (!props.hosts) {
        return <></>
    }
    const markers = props.hosts.map(complex => {
        if (
            complex.inventory.location_lat === "" &&
            complex.inventory.location_lon === ""
        ) {
            return null;
        }
        if (complexesFilterPayload.severity && complexesFilterPayload.severity > 0) {
            const triggers = (complex.triggers.find((t) => parseInt(t.priority, 10) >= complexesFilterPayload.severity)) ? true : false
            if (!triggers) { 
                return null
            }
        }
        const triggetMaxPriority = getTriggerMaxPriority(complex)

        return (
            <Marker
                position={[
                    complex.inventory.location_lat,
                    complex.inventory.location_lon,
                ]}
                icon={getIcon(complex, triggetMaxPriority)}
                key={complex.hostid}
                data={complex.hostid}
                trigger={triggetMaxPriority}
                zIndexOffset={triggetMaxPriority*100}
            >
                <MarkerPopup complex={complex} key={`popup_${complex.hostid}`} />
                {/* <MarkerTooltip complex={complex} key={`tooltip_${complex.hostid}`}/> */}
                {
                    complex.macros.find(item => item.macro === '{$POWER}')?.value === 'BAT' ?
                    <MarkerTooltip complex={complex} key={`tooltip_${complex.hostid}`}/>
                        :
                        null

                }
            </Marker>
        );
    });
    
    return <MarkerClusterGroup key={Date.now()} maxClusterRadius={22} iconCreateFunction={createClusterCustomIconForSpeed} removeOutsideVisibleBounds >
                {markers}
            </MarkerClusterGroup>
}

