import Dropdown from 'react-bootstrap/Dropdown';
import '../../../styles/MarkerPopup.css'

const DropdownLinks = (props) => {
    const interfaces = props.complex.interfaces.filter((p) => p.type === '1')
    if (!interfaces) return null
    return (
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <span className='fa fa-link'></span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              interfaces.map((int) => {
                const t = () => { window.open("http://" + int.ip); }
                return <Dropdown.Item key={int.ip} onClick={t}>{int.ip}</Dropdown.Item>
              })
            }
          </Dropdown.Menu>
        </Dropdown>
    );
}

export default DropdownLinks;