import { getIconBattery, getPowerOff, getPowerPlug } from "../helper.js"

const TooltipContent = props => {
    // console.log(props.complex)
    // const voltage = props.complex.items.find(item => item.key_ === 'mssVoltage' || item.key_ === 'voltage_board' || item.key_ === "pwr")
    const bp = props.complex.items.find(item => item.key_ === 'bp')
    // const poweroff = props.complex.items.find(item => item.key_ === 'gpio1_status')
    // const powerplug = props.complex.items.find(item => item.key_ === 'gpio2_status')
    // console.log(powerplug)
    // if (powerplug && powerplug.lastvalue === "1") { console.log('powerplug')}
    if (bp) {
        return (
            getIconBattery(bp.lastvalue)
            // <div>
            //     {(bp) ? getIconBattery(bp.lastvalue) : null}
            //     {(poweroff && poweroff.lastvalue === "1") ? getPowerOff() : null}
            //     {(powerplug && powerplug.lastvalue === "1") ? getPowerPlug() : null}
            // </div>
            
        )
    }

}

export default TooltipContent;





