import { Col, Form, Row } from "react-bootstrap"
import { useRef, useEffect, useState } from "react";
import {  drawGRZ, drawGeometryLines, drawMarkLines } from "../../../utils/skats";
import md5 from "md5";
import { skatTimeToString } from "../../../utils/converters";
import { OrbitProgress } from "react-loading-indicators";

const ComplexViewSkatS = props => {
    
    console.log('ComplexViewSkatS')
    console.log(props)
    console.log(props.complex.interfaces[1].ip)
    const canvasRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isPaused, setIsPaused] = useState(false);
    const ws = useRef(null);
    const [config, setConfig] = useState({
        geometry: false,
        marking: false,
        grz: false,
        k: 0.3,
        q: 20,
        width_cam: 1168,
        height_cam: 600,
        pass_hash: 'ec6b5c0313ea1ce19624455f798ed289',
        message: {
            method: "authentication",
            params: {
                name: 'Администратор',
                stage: 1
            }
        }
    })

    const [data, setData] = useState({
        lat: '--.-',
        lon: '--.-',
        time: '--.-',
        volt: '--.-',
        temp: '--'
    })


    useEffect(() => {
        // if (!isPaused) {
            try {
                if(window.location.protocol === 'http:') {
                    ws.current = new WebSocket("ws:/" + props.complex.interfaces[1].ip + ":7681"); // создаем ws соединение
                } else {
                    ws.current = new WebSocket("wss://map.infosys05.ru/ws?complex=" + props.complex.interfaces[1].ip); // создаем ws соединение
                }
           
            
            ws.current.onopen = () => {
                console.log("Соединение установлено.");
                var message = {
                    method: "authentication",
                    params: {
                        name: 'Администратор',
                        stage: 1
                    },
                    id: 1
                }
                message.id = 1
                ws.current.send(JSON.stringify(message))
            };
            ws.current.onclose = (event) => {
                if (event.wasClean) {
                    console.log('Соединение закрыто чисто');
                    setIsLoading(true)
                } else {
                    console.log('Обрыв соединения'); // например, "убит" процесс сервера
                }
                console.log('Код: ' + event.code + ' причина: ' + event.reason);
            }

            // gettingData();
            return () => ws.current.close(); // кода меняется isPaused - соединение закрывается
            } catch (e) {
                console.log(e)
            }
            
        // }
    }, [ws, props.complex.interfaces]);

    // const gettingData = useCallback(() => {
    useEffect(() => {
        // console.log(config)
        if (!ws.current) return;

        var message
        ws.current.onmessage = (event) => {                //подписка на получение данных по вебсокету
            // if (isPaused) return;
            const e = JSON.parse(event.data);
            if (e.id === 1) {
                console.log(e)
                const key = md5(e.result.generated_key + config.pass_hash)
                message = config.message
                message.params.stage = 2;
                message.params.authorize_key = key;
                message.id = 2;
                ws.current.send(JSON.stringify(config.message))
            }
            if (e.id === 2) {
                message = { method: "get_info", id: 3 }
                ws.current.send(JSON.stringify(message))
            }
            if (e.id === 3) {
                setData({
                    ...data,
                    info: e.result,
                    lat: e.result.gns.latitude,
                    lon: e.result.gns.longitude,
                    volt: e.result.volt,
                    temp: e.result.temp
                })
                message = { method: "get_uconfig", id: 4 }
                ws.current.send(JSON.stringify(message))
            }
            if (e.id === 4) {
                setData({
                    ...data,
                    uconfig: e.result
                })
                message = { method: "get_mconfig", id: 5 }
                ws.current.send(JSON.stringify(message))

            }
            if (e.id === 5) {
                setData({
                    ...data,
                    mconfig: e.result,
                    width_cam: e.result.camera.frame_width,
                    height_cam: e.result.camera.frame_height
                })
                setConfig({
                    ...config,
                    width_cam: Math.round(e.result.camera.frame_width * config.k),
                    height_cam: Math.round(e.result.camera.frame_height * config.k)
                })
                message = { id: 6, method: "get_frame", params: {
                    quality: config.q, 
                    size: {width: config.width_cam, config: data.height_cam}, 
                    fit: true, 
                    with_recogn: true 
                }}
                console.log(message)
                ws.current.send(JSON.stringify(message))
            }
            if (e.id === 6) {
                // console.log(6)
                console.log(e)
                // console.log(data)
                // console.log(config)
                
                setData({
                    ...data,
                    // time: new Date(1970, 0, 1).setSeconds(e.result.time).toString()
                    time: skatTimeToString(e.result.time),
                    stage: 6
                })
                setIsLoading(false)
                if (!isLoading && !isPaused) {
                    const ctx = canvasRef.current.getContext("2d");
                    const base_image = new Image();
                    base_image.src = e.result.image.data;
                    base_image.onload = function() {

                        ctx.drawImage(base_image, 0, 0);
                        if (config.geometry) {
                            console.log('geometry')
                            drawGeometryLines(ctx, config.k, data.uconfig.mount_markup_points,  data.width_cam, data.height_cam, data.mconfig.camera.aov_h, data.mconfig.camera.aov_v, data.uconfig.mount_rotation, data.uconfig.mount_slope)
                        }
                        if (config.marking) {
                            drawMarkLines(ctx, 0, config.k, data.uconfig.zones_markup.lines)
                        }
                        if (config.grz) {
                            if (e.result.recogn.length > 0) {
                                drawGRZ(ctx, config.k, e.result.recogn)
                            }
                        }
                    
                    
                        ctx.stroke();
                        
                    }
                }
                
                message = { id: 6, method: "get_frame", params: {
                        quality: config.q, 
                        size: {width: config.width_cam, height: config.height_cam}, 
                        fit: true, 
                        with_recogn: true 
                    }}
                ws.current.send(JSON.stringify(message))
                // ws.current.close()
            }
        };
    }, [config, data, isLoading, isPaused]);

    


    // useEffect(() => { 
    //     const socket = new WebSocket("ws:/" + props.complex.interfaces[1].ip + ":7681");
    //     const login = 'Администратор'
    //     const pass_hash = 'ec6b5c0313ea1ce19624455f798ed289'
    //     var message = {
    //         method: "authentication",
    //         params: {
    //             name: login,
    //             stage: 1
    //         }
    //     }





    //     const canvas = canvasRef.current; 
    //     const context = canvas.getContext('2d'); 
    //     const image = new Image(); 
    //     const handleImageLoad = () => { 
    //         context.drawImage(image, 0, 0); // Draw the image at position (0, 0) on the canvas 
    //     }; 
    //     image.addEventListener('load', handleImageLoad); 
    //     image.src = 'path_to_your_image.jpg'; // Set the image source 
    //     return () => { 
    //         image.removeEventListener('load', handleImageLoad); // Remove the event listener on cleanup 
    //     }; 
    // }, []);


    return (
        <Form>
            <Row >
                <Form.Group as={Col}  id="formGridCheckbox">
                    <Form.Check type="checkbox" label="Наведение"  onChange={(e) => {
                        setConfig({...config, geometry: e.target.checked}) 
                    }} />
                </Form.Group>
                <Form.Group as={Col}  id="formGridCheckbox">
                    <Form.Check type="checkbox" label="Разметка" onChange={(e) => setConfig({...config, marking: e.target.checked})}/>
                </Form.Group>
                <Form.Group as={Col}  id="formGridCheckbox">
                    <Form.Check type="checkbox" label="ГРЗ" onChange={(e) => setConfig({...config, grz: e.target.checked})}/>
                </Form.Group>
                <Form.Group as={Col}  id="formGridCheckbox">
                    <Form.Label>Масштаб</Form.Label>
                    <input type="number" id="scale" name="scale" min="0.1" max="1" step="0.1" value={config.k} onChange={(e) => {
                        setConfig({...config, 
                            k: e.target.value, 
                            width_cam: Math.round(data.width_cam * e.target.value),
                            height_cam: Math.round(data.height_cam * e.target.value)
                        })
                    }} />
                </Form.Group>
                <Form.Group as={Col}  id="formGridCheckbox">
                    <Form.Label>Качество</Form.Label>
                    <input type="number" id="quality" name="quality" min="5" max="100" step="5" value={config.q} onChange={(e) => setConfig({...config, q: e.target.value})} />
                </Form.Group>
            </Row>
            <Row>

           
                {/* <canvas onClick={(e) => setIsPaused(!isPaused)} width={config.width_cam} height={config.height_cam} ref={canvasRef}></canvas> */}
                {isLoading ?
                <div style={{ width: "100%", height: "200px", display: 'flex', justifyContent: 'center', border: "1px solib black", alignItems: "center"}}>
                    
                    <OrbitProgress color="green" size="medium" text="" textColor="" />
                      
                    
                </div>

                : <canvas onClick={(e) => {
                        
                    if (isPaused && ws.current) {
                        const message = { id: 6, method: "get_frame", params: {
                            quality: config.q, 
                            size: {width: config.width_cam, height: config.height_cam}, 
                            fit: true, 
                            with_recogn: true 
                        }}
                        ws.current.send(JSON.stringify(message))
                    }
                    setIsPaused(!isPaused)
                    
                }} width={config.width_cam} height={config.height_cam} ref={canvasRef}></canvas>
                } 
            </Row>  
            <Row >
                <Form.Group as={Col}  id="formGridCheckbox">
                    <Form.Label column sm={2}>Координаты</Form.Label>
                    <Form.Label column sm={4}>N{Number(data.lat).toFixed(6)} E{Number(data.lon).toFixed(6)}</Form.Label>
                </Form.Group>
            </Row> 
            <Row>
                <Form.Group as={Col} id="formGridCheckbox">
                    <Form.Label column sm={2}>Время</Form.Label>
                    <Form.Label column sm={4}>{data.time}</Form.Label>
                </Form.Group>
            </Row> 
            <Row>
                <Form.Group as={Col} id="formGridCheckbox">
                    <Form.Label column sm={2}>Напряжение</Form.Label>
                    <Form.Label column sm={4}>{data.volt} V</Form.Label>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} id="formGridCheckbox">
                    <Form.Label column sm={2}>Температура</Form.Label>
                    <Form.Label column sm={4}>{data.temp} C</Form.Label>
                </Form.Group>

            </Row> 
             
        </Form>
    )
}

export default ComplexViewSkatS

