import '../../../styles/MarkerTooltip.css'
import TooltipContent from './TooltipContent'
import { Tooltip } from 'react-leaflet'
const MarkerTooltip = (props) => {
    return (
        <Tooltip permanent>
            <TooltipContent complex={props.complex} />
        </Tooltip>
    )
}

export default MarkerTooltip