import "../../styles/ModalStat.css"
import "../../styles/Modal.css"
import getStat from "../../http/statApi";
import { Form, Row, Col, Tab, Tabs } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useCallback, useState,  useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Badge from "react-bootstrap/Badge";
import { forwardRef } from "react";

const ModalStat = props => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [statisic, setStatisic] = useState([])
    const [fixations, setFixations] = useState(0)
    const [violations, setViolations] = useState(0)
    const [key, setKey] = useState('crossroads');

    const InputStartDate = forwardRef(({ value, onClick }, ref) => (
        <button className="input-start" onClick={onClick} ref={ref}>
          {value}
        </button>
    ));

    const InputEndDate = forwardRef(({ value, onClick }, ref) => (
        <button className="input-end" onClick={onClick} ref={ref}>
          {value}
        </button>
    ));
    const action = useCallback(async () => {
        if (!startDate || !endDate) {
            console.log(startDate, endDate)
            return
        }

        const data = await getStat(startDate, endDate)
        console.log(data)
        setStatisic(data.filter(obj => obj.name))
        setFixations(data.reduce((acc, item) => acc + item.totalFixations, 0))
        setViolations(data.reduce((acc, item) => acc + item.totalViolations, 0))
    }, [startDate, endDate])

    useEffect(() => {
        action().catch(e => console.log(e))
    }, [startDate, endDate, action])



    return (
        <Modal show={props.visible}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            fullscreen
            style={{ paddingTop: '20px', paddingBottom: '20px'}}
            onEscapeKeyDown={() => props.close()}
        >
            <Modal.Header className='modal_stat_header'>
                {/* <Modal.Title >Статистика</Modal.Title> */}
                <div style={
                {
                    display: 'flex',
                    justifyContent: "center",
                    marginTop: "10px",
                }}>
            
                    <span className="text-left-start">Статистика c</span>
                    <DatePicker
                            selected={startDate}
                            onChange={setStartDate}
                            dateFormat={"dd.MM.yyyy"} 
                            customInput={<InputStartDate />}
                        />
                    <span className="text-beetwen" >по</span>
                    <DatePicker
                            selected={endDate}
                            onChange={setEndDate} 
                            dateFormat={"dd.MM.yyyy"}
                            customInput={<InputEndDate />} 
                            
                        />
            </div>
            </Modal.Header>

            <Modal.Body>
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3 modal_info_body_tabs"
                    onSelect={(k) => setKey(k)}
                    activeKey={key}
                    
                >
                    <Tab eventKey="crossroads" title="Перекрестки">          
                        <Form>
                            <Row key={0}>
                                <Col sm={9} style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}>Комплекс</Col>
                                <Col sm={1} style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}>Фиксаций</Col>
                                <Col sm={1} style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}>Нарушений</Col>
                            </Row>
                            <hr style={{ margin: '2px'}}/>
                        {
                            statisic.filter(host => host.type === 'crossroad').map(host => {
                                return <Row style={{ padding: '2px'}} key={host.host}>
                                    <Col sm={9}>{host.name}</Col>
                                    <Col sm={1} style={{ display: 'flex', justifyContent: 'center'}}>{host.totalFixations}</Col>
                                    <Col sm={1} style={{ display: 'flex', justifyContent: 'center'}}>{host.totalViolations}</Col>
                                    
                                </Row>
                            })
                        }
                        </Form> 
                    </Tab>
                    
                    <Tab eventKey="speed" title="Скоростные">
                        <Form>
                            <Row key={0}>
                                <Col sm={9} style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}>Комплекс</Col>
                                <Col sm={1} style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}>Фиксаций</Col>
                                <Col sm={1} style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}>Нарушений</Col>
                            </Row>
                            <hr style={{ margin: '2px'}}/>
                        {
                            statisic.filter(host => host.type === 'speed').sort().map(host => {
                                return <Row style={{ padding: '2px'}} key={host.host}>
                                    <Col sm={9}>{host.name}</Col>
                                    <Col sm={1} style={{ display: 'flex', justifyContent: 'center'}}>{host.totalFixations}</Col>
                                    <Col sm={1} style={{ display: 'flex', justifyContent: 'center'}}>{host.totalViolations}</Col>
                                    
                                </Row>
                            })
                        }
                        </Form> 
                    </Tab>

                    <Tab eventKey="parking" title="Парковка">
                        <Form>
                            <Row key={0}>
                                <Col sm={9} style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}>Комплекс</Col>
                                <Col sm={1} style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}>Фиксаций</Col>
                                <Col sm={1} style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}>Нарушений</Col>
                            </Row>
                            <hr style={{ margin: '2px'}}/>
                        {
                            statisic.filter(host => host.type === 'parking').map(host => {
                                return <Row style={{ padding: '2px'}} key={host.host}>
                                    <Col sm={9}>{host.name}</Col>
                                    <Col sm={1} style={{ display: 'flex', justifyContent: 'center'}}>{host.totalFixations}</Col>
                                    <Col sm={1} style={{ display: 'flex', justifyContent: 'center'}}>{host.totalViolations}</Col>
                                    
                                </Row>
                            })
                        }
                        </Form>
                        
                        
                    </Tab>
           

                </Tabs>
           
                


            </Modal.Body>

            <Modal.Footer style={{ display: 'inline-table', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div float='left' left='0' right='auto'>
                    <span>Всего проездов </span>
                    <Badge bg="dark" style={{ marginRight: "3px"}}>{fixations}</Badge>
                    <span>/ нарушений </span>
                    <Badge bg="warning" style={{ marginLeft: "3px"}}>{violations}</Badge>
                    <Button style={{ float: 'right', left: 'auto', right:'0' }} size="sm" variant="primary" onClick={() => {

                        props.close()
                    }}>
                        Закрыть
                    </Button>
                    {/* <Button style={{ float: 'right', left: 'auto', right:'0' }} variant="success" onClick={console.log('toExcel')} size='sm'>Выгрузить XLSX</Button> */}
                </div>
                {/* &nbsp; */}
                
            </Modal.Footer>
        </Modal>
        // <div>
            // <div style={
            //     {
            //         display: 'flex',
            //         justifyContent: "space-between",
            //         marginTop: "10px",
            //     }}>
            //     <div style={{ width: '400px' }}>
            //         <InputGroup className="mb-3" style={{display: 'flex', justifyContent:'center'}}>
            //             <InputGroup.Text >Начало периода</InputGroup.Text>
            //             <DatePicker onChange={setStartDate} value={startDate} />

            //         </InputGroup>
            //     </div>

            //     <div style={{ width: '400px'}}>
            //         <InputGroup className="mb-3" style={{display: 'flex', justifyContent:'center'}}>
            //             <InputGroup.Text >Конец периода</InputGroup.Text>
            //             <DatePicker onChange={setEndDate} value={endDate} />

            //         </InputGroup>
            //     </div>


            // </div>

        //     <div >
        //         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        //             <div>Проездов за период:</div>
        //             <div>{fixations}</div>
        //         </div>
        //         <div style={{ display: 'flex', justifyContent: 'space-between' }}  >
        //             <div>Нарушений за период:</div>
        //             <div>{violations}</div>

        //         </div>
        //     </div>
        // </div>
    )
}

export default ModalStat;