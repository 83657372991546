import Accordion from 'react-bootstrap/Accordion';
import { itemsNormalizer } from '../../../utils/converters';
const ComplexData = props => {


    var active_tabs = [props.complex.hostid]
    props.complex.child.map(item => {
        active_tabs = [item.hostid, ...active_tabs]
    })
    return (
        <Accordion defaultActiveKey={active_tabs} alwaysOpen>
            <Accordion.Item key={props.complex.hostid} eventKey={props.complex.hostid}>
                <Accordion.Header as={'h1'}>Данные из БД</Accordion.Header>
                <Accordion.Body>
                    {
                        itemsNormalizer(props.complex).items.map(item => {
                            if (item.status === "0") {
                                return (<li key={`${item.key_}`}><span> {item.name} : {item.lastvalue} {item.units}</span></li>)
                            }
                        })
                    }
                                            
                </Accordion.Body>
            </Accordion.Item>
            {
                props.complex.child.map(host => {
                    return (
                        
                        <Accordion.Item key={host.hostid} eventKey={host.hostid}>
                            <Accordion.Header as={'h1'}>{host.name.split('/')[1]}</Accordion.Header>
                            <Accordion.Body>
                                {
                                    itemsNormalizer(host).items.map(item => {
                                        if (item.status === "0") {
                                            return (<li key={`${item.key_}`}><span> {item.name} : {item.lastvalue} {item.units}</span></li>)
                                        }
                                    })
                                }
                                                       
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })
            }
        </Accordion>
    )


}

export default ComplexData