import NavBar from './Navbar';
import { Route, Routes, BrowserRouter as Router, Navigate } from "react-router-dom";
import { UserContext } from '../context';
import { useContext } from 'react';
import MapPage from '../pages/MapPage';
import LoginPage from '../pages/LoginPage';

const RouterContent = () => {
    const { userPayload } = useContext(UserContext)
    const { isAuth } = userPayload
    return (
        <Routes>
            
            {/* <Route path="map" element={isAuth ? <MapPage /> : <Navigate to='/login' />} /> */}
            {/* <Route path="adm" element={isAuth && isAdmin ? <AdminPage /> : <Navigate to='/login' />} /> */}
            <Route path="login" element={<LoginPage />} />
            <Route path="*" element={isAuth  ? <MapPage /> : <Navigate to='/login' />} />

        </Routes>



    )
}

const AppRouter = () => {

    return (
        // <Container fluid>
            <Router>
                <NavBar />
                <RouterContent />
            </Router>
        // </Container>
    )
}

export default AppRouter;