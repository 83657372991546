import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Accordion, Card, Col, Container, Dropdown, Form, Row, SplitButton } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { getUsers, addUser, editUser, deleteUser, getRoles, addRole } from '../../http/userApi';

import "../../styles/ModalSearch.css"


const ModalUsers = props => {
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [data, setData] = useState(null)
    const [addUserClicked, setAddUserClicked] = useState(null)
    const [editUserClicked, setEditUserClicked] = useState(null)

    useEffect(() => {
        getUsers().then((res) => {
            setUsers(res.data)
        })
        getRoles().then((res) => {
            setRoles(res.data)
        })
        setRefresh(false)
    }, [refresh])


    useEffect(() => {
        console.log(data)
    }, [data])

    function del(user) {
        deleteUser(user).then((res) => {
            setRefresh(true)
            setAddUserClicked(null)
            setEditUserClicked(null)
        })
    }

    function add(user) {
        addUser(user).then((res) => {
            setRefresh(true)
            setAddUserClicked(null)
        })
    }

    function edit(user) {
        editUser(user).then((res) => {
            setRefresh(true)
            setEditUserClicked(null)
        })
    }

    return (
        <Modal show={props.visible}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onEscapeKeyDown={() => props.close()}
            scrollable
        >
            <Modal.Header style={{ justifyContent: 'center'}}>
                <Modal.Title >Пользователи</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        {/* <Col>ID</Col> */}
                        <Col>Логин</Col>
                        <Col>Имя</Col>
                        <Col>Роль</Col>
                        <Col>Комментарий</Col>
                        <Col></Col>
                    </Row>
                    <hr style={{ margin: '0'}}/>
                    {
                        users.map((user) => {
                            return (

                                <Row key={user.id}>
                                    <Col>{user.login}</Col>
                                    <Col>{user.name}</Col>
                                    <Col>{user.roles.join(', ')}</Col>
                                    <Col>{user.comments}</Col>
                                    <Col style={{ display: 'flex', justifyContent: 'space-between'}}>
                                        <span style={{ color: 'orange' }} className='fa fa-edit' onClick={() => {
                                            setData(user)
                                            setEditUserClicked(user); 
                                            setAddUserClicked(null);
                                        }}></span>
                                        <span style={{ color: 'red' }} className='fa fa-trash' onClick={() => {
                                            // setData(user)
                                            del(user)
                                        }}></span>
                                    </Col>                                    
                                </Row>
                            )
                        })
                    }
                    <hr style={{ margin: '5px'}}/>

                    {addUserClicked ?
                    
                    <Row>
                        <Col><Form.Control size="sm" type="text" placeholder="Логин" onChange={(e) => setData({...data, login: e.target.value })} /></Col>
                        <Col><Form.Control size="sm" type="text" placeholder="Имя" onChange={(e) => setData({...data, name: e.target.value })} /></Col>
                        <Col>

                            <Form.Select onChange={(e) => setData({...data, roles: e.target.value.split(',') })}>
                            {
                                roles.map((role) => {
                                    return (
                                        <option value={role.name}>{role.name}</option>
                                    )
                                })
                            }
                        
                            
                            </Form.Select>
                            </Col>
                        <Col><Form.Control size="sm" type="text" placeholder="Комментарий" onChange={(e) => setData({...data, comments: e.target.value })} /></Col>
                        <Col><Form.Control size="sm" type="text" placeholder="Пароль" onChange={(e) => setData({...data, password: e.target.value })} /></Col>
                        <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} sm={1}>
                            <span style={{ color: 'green', padding: '3px' }} className='fa fa-check-circle' onClick={() => add(data)}></span>
                            <span style={{ color: 'red',  padding: '3px' }} className='fa fa-ban' onClick={() => {
                                setData(null)
                                setAddUserClicked(null)
                            }}></span>
                        </Col>
                    </Row>
                    : null}

                    {editUserClicked ?
                    <Row>
                        <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{data.login}</Col>
                        <Col><Form.Control size="sm" type="text" placeholder="Имя" defaultValue={data.name} onChange={(e) => setData({...data, name: e.target.value })}/></Col>
                        <Col>
                            <Form.Select value={data.roles[0]} onLoad={(e) => setData({...data, roles: e.target.value.split(',') })} onChange={(e) => setData({...data, roles: e.target.value.split(',') })}>
                            {
                                roles.map((role) => {
                                    return (
                                        <option value={role.name}>{role.name}</option>
                                    )
                                })
                            }
                            </Form.Select>
                        </Col>
                        <Col><Form.Control size="sm" type="text" placeholder="Комментарий" defaultValue={data.comments} onChange={(e) => setData({...data, comments: e.target.value })}/></Col>
                        <Col><Form.Control size="sm" type="text" placeholder="Пароль" onChange={(e) => setData({...data, password: e.target.value })}/></Col>
                        <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} sm={1}>
                            <span style={{ color: 'green', padding: '3px'  }} className='fa fa-check-circle' onClick={() => {edit(data)}}></span>
                            <span style={{ color: 'red',  padding: '3px' }} className='fa fa-ban' onClick={() => {
                                setData(null)
                                setEditUserClicked(null)
                            }}></span>
                            </Col>
                    </Row>
                    : null}
                    {editUserClicked || addUserClicked ? <hr style={{ margin: '5px'}}/> : null}
                    <Row style={{display: 'flex', justifyContent: 'center'}}>
                        <Col sm={1}>
                            <span style={{ color: 'green', scale: '1.5' }} className='fa fa-plus-circle' onClick={() => {
                                setData({roles: ['user']})
                                setAddUserClicked({}); 
                                setEditUserClicked(false)
                            }}></span>
                        </Col>
                    </Row>
                </Container>
                
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="primary" onClick={() => {
                    props.close()
                }}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalUsers
