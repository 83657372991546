import { getIconBattery } from "../helper.js"

const TooltipContent = props => {
    const voltage = props.complex.items.find(item => item.key_ === 'mssVoltage' || item.key_ === 'voltage_board' || item.key_ === "pwr")
    if (voltage) {
        const accVoltage = voltage.lastvalue
        let level = "bat-4"
        if (accVoltage < 12) level = "bat-3"
        if (accVoltage < 11.7) level = "bat-2"
        if (accVoltage < 11.3) level = "bat-1"
        return (
             // <div className={level}>
            // </div>

            getIconBattery(accVoltage)
        )
    }

}

export default TooltipContent;





