
export function convert2dTo3d(p2, y, w, h, aovH, aovV, rotat, slope){
    var hv = h/2;
    var hh = w/2;
    var kh = aovH / w;
    var kv = aovV / h;
    var angleVert = hv - p2.y;
    var angleHor = p2.x - hh;
    angleHor *= kh;
    angleVert *= kv;
    angleHor += rotat;
    angleVert = slope - angleVert; 
    angleHor = angleHor*Math.PI/180.0;
    angleVert = angleVert*Math.PI/180.0;
    var rH =(-y) / Math.sin(angleVert);
    var p3 = 
    {
        x: rH*Math.cos(angleVert)*Math.sin(angleHor),
        y: y,
        z: rH*Math.cos(angleVert)*Math.cos(angleHor)			
    }; 
    return p3;
}


export function convert3dTo2d(p3, w, h, aovH, aovV, rotat, slope){
    var roq = Math.sqrt(p3.x*p3.x+p3.z*p3.z);
    var x2d = Math.asin(p3.x/roq)*180/Math.PI - rotat;
    var y2d = Math.atan(p3.y/roq)*180/Math.PI + slope;
    var p2 = 
    {
        x: w *(0.5 + x2d / aovH),
        y: h *(0.5 - y2d / aovV)
    }
    return p2;
}

export function drawGeometryLines(ctx, k, mount_markup_points, frame_width, frame_height, aov_h, aov_v, mount_rotation, mount_slope) {
    var ctx_s = 
        [
            [14,'#002200'],
            [6,'#AAFFAA'],
            [4,'#00FF00'],
        ];
    for(var i=0;i<mount_markup_points.length;i++) {
            var p1 = mount_markup_points[i];

            // Если точка находится за пределами кадра, то ее x и y выставляются рандомно в пределах от 300 до (frame_width - 300), так же и для height
            if (p1.x < 0 || p1.y < 0 || p1.x >= frame_width || p1.y >= frame_height)
            {
                console.log("Точки НАХОДЯТСЯ ЗА ПРЕДЕЛАМИ кадра!!! Исправление...");
                p1.x = Math.random() * ((frame_width - 300) - 300) + 300;
                p1.y = Math.random() * ((frame_height - 300) - 300) + 300;	
            }
            
            var p2_3d = convert2dTo3d(p1,0,frame_width,frame_height,aov_h,aov_v,mount_rotation,mount_slope);
            p2_3d.z = 100000;
            var p2 = convert3dTo2d(p2_3d, frame_width,frame_height,aov_h,aov_v,mount_rotation,mount_slope);
            var p3 = {};
            var dx = p2.x - p1.x;
            var dy = p2.y - p1.y;
            p3.x = p1.x-dx*20;
            p3.y = p1.y-dy*20;
        //	ctx.setLineDash([50, 30]);
            // var ctx = document.getElementById("skat_test").getContext("2d");
            for(var s = 0; s < ctx_s.length; s++)
            {
                ctx.lineWidth=Math.round(ctx_s[s][0] * k);
                ctx.strokeStyle=ctx_s[s][1];
                
                ctx.beginPath();
                ctx.moveTo(k * p1.x, k * p1.y);
                ctx.lineTo(k * p2.x, k * p2.y);
                ctx.stroke();
                
                ctx.beginPath();
                ctx.moveTo(k * p1.x, k * p1.y);
                ctx.lineTo(k * p3.x, k * p3.y);
                ctx.stroke();
            }
        }
}
export function drawGRZ(ctx, k, recogn) {
    for(var i=0;i<recogn.length;i++) {
        ctx.fillStyle = "red";
        ctx.font = "30px Consolas";
        ctx.fillText(recogn[i].string, k * recogn[i].frame[0], k * recogn[i].frame[1], k * recogn[i].frame[2]);
        ctx.stroke();
   }
}
export function drawMarkLines(ctx, sc, k, zones_markup_lines)
{
    console.log("draw")
    //var radius = 15/sc;
    //radius = draw_marker_radius;
    //var ctx_s = me.draw_zones_state==true ? [[14,'#002200'],[6,'#AAFFAA'],[4,'#00FF00'],] : [[14,'#474747'],[6,'#d8d8d8'],[4,'#a5a5a5'],];

    //var ctx_s = me.draw_states==me.DrawStatesEnum.zones ? [[14,'#002200'],[6,'#AAFFAA'],[4,'#00FF00'],] : [[14,'#474747'],[6,'#d8d8d8'],[4,'#a5a5a5'],];
    var ctx_s = [[14,'#002200'],[6,'#A67C00'],[4,'#FFBF00'],];

    for(var i=0;i<zones_markup_lines.length;i++)
    {
        var ln = zones_markup_lines[i];
         
        if(ln.p2)
        {
            for(var s = 0; s < ctx_s.length; s++)
            {
                ctx.lineWidth=Math.round(ctx_s[s][0] * k);
                ctx.strokeStyle=ctx_s[s][1];

                
                var dx = (ln.p2.x-ln.p1.x)*10000;
                var dy = (ln.p2.y-ln.p1.y)*10000;
                
                
                
                ctx.beginPath();
                ctx.moveTo(k * ln.p1.x,k * ln.p1.y);
                ctx.lineTo(k * (ln.p1.x + dx),k * (ln.p1.y + dy));
                ctx.moveTo(k * ln.p2.x,k *  ln.p2.y);
                ctx.lineTo(k * (ln.p2.x - dx),k * (ln.p2.y - dy));
                ctx.stroke();
            }
            // if (me.draw_states==me.DrawStatesEnum.zones) {
            // 	me.drawMarker(ctx,ln.p1,radius,'#00FF00',ctx_s);
            // 	me.drawMarker(ctx,ln.p2,radius,'#00FF00',ctx_s);
            // }
        }
        // else
        // {
        // 	// me.drawMarker(ctx,ln.p1,radius,'#FFAA00',ctx_s); 
        // } 
    }
}