import Accordion from 'react-bootstrap/Accordion';
import { getProblems } from "../../markers/helper";

const ComplexChilds = props => {


    var active_tabs = []
    props.childs.map(item => {
        if (item.triggers.length > 0) {
            active_tabs = [item.hostid, ...active_tabs]
        }
    })

    return (
        <Accordion defaultActiveKey={active_tabs} alwaysOpen>
            {
                props.childs.map(host => {
                    return (
                        <Accordion.Item key={host.hostid} eventKey={host.hostid}>
                            <Accordion.Header as={'h1'}>{host.name.split('/')[1]}</Accordion.Header>
                            <Accordion.Body>
                                {
                                    host.triggers.length === 0 ?
                                    <span style={{ color: "green" }} key={`${host.hostid}_0`}>Работа в штатном режиме</span> :
                                    getProblems(host).map(problem => {
                                        console.log(problem)
                                        return (
                                            <li key={`${problem[0]}`}><span style={{ color: problem[4], padding: '2px' }}> {problem[2]} : {problem[3]}</span></li>
                                        )
                                    })
                                }
                                                       
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })
            }
        </Accordion>
    )


}

export default ComplexChilds