const TooltipContent = props => {
    const info = props.complex.filter(child => child.triggers.length !== 0)
    const complexInfo = {
        acknowleged: false,
        vscGood: 0,
        vscBad: 0,
        axisGood: 0,
        axisBad: 0
    }

    if (info.length > 0) {

        props.complex.forEach(device => {
            // проверяем подтверждения до упора
            if(!complexInfo.acknowleged){
                for(let trigger of device.triggers){
                    if (trigger.problem.acknowledged === "1") {
                        complexInfo.acknowleged = true        
                    } 
                    break
                }
            }

            if (device.host.split('-')[2].startsWith("VSC")) {
                if(device.triggers.length > 0){
                    complexInfo.vscBad++
                } else {
                    complexInfo.vscGood++
                }
                
            }

            if (device.host.split('-')[2].startsWith("Axis")) {
                if(device.triggers.length > 0){
                    complexInfo.axisBad++
                } else {
                    complexInfo.axisGood++
                }
            }
        })
        return (
            <div className="tooltip-content" style={{ fontWeight: "600", backgroundColor: "#fff", padding: "3px", border: "1px solid #fff", borderRadius: "3px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {
                        complexInfo.acknowleged ? 
                        <span style={{ color:"green" }} className="fa fa-cog fa-2x fa-spin"></span> : 
                        ''
                    }
                </div>
                <div>VSC: <span style={{ color:"green" }}>{complexInfo.vscGood}</span>/<span style={{ color:"red" }}>{complexInfo.vscBad}</span> </div>
                <div>AXIS: <span style={{ color:"green" }}>{complexInfo.axisGood}</span>/<span style={{ color:"red" }}>{complexInfo.axisBad}</span></div>
            </div>
        )

    }
       

    return <></>

}

export default TooltipContent;





