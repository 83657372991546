import LoginForm from "../components/LoginForm"
import "../styles/LoginPage.css"
import { Navigate } from "react-router-dom"
import { UserContext } from "../context"
import { useContext } from "react"


const LoginPage = () => {
    const { userPayload } = useContext(UserContext)
    const { isAuth } = userPayload
    if (isAuth) {
        return <Navigate to='/map' />
    }
    return (
        <div className="login__container">
                <LoginForm />
        </div>
    )
}

export default LoginPage