import L from "leaflet";
import "leaflet-extra-markers";
import "font-awesome/css/font-awesome.css";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import { uptimeToString } from '../../utils/converters'
import '../../styles/Icon.css'


// отображение батареи в зависимости от напряжения
export function accDiv(complex) {
    const powerMacro = complex.macros.find(item => item.macro === '{$POWER}')
    if (powerMacro && powerMacro.value === 'BAT') {
        const voltage = complex.items.find(item => item.key_ === 'mssVoltage' || item.key_ === 'voltage_board' || item.key_ === "pwr")
        if (voltage) {
            const accVoltage = voltage.lastvalue
            let level = 4
            if (accVoltage < 12) level = 3
            if (accVoltage < 11.7) level = 2
            if (accVoltage < 11.3) level = 1
            return `<div className="bat-${level}"></div>`
        }
    }
    return ''
}


export function getTooltip(complex) {
    //показ значка батареи в зависимости от уровня заряда
    if (complex.macros.find(macro => macro.macro === '{$POWER}')) {
        return `
            <style>
                .leaflet-tooltip {
                    position: absolute;
                    background-color: rgba(0, 0, 0, 0.1) !important;
                    border: 0px solid #000;
                    color: #000;
                    padding: 0px;
                }
                .bat-4 {
                    width: 40px;
                    height: 14px;
                    background: url('/img/battery/bat-4.png');
                    background-size: 40px;
                }
                .bat-3 {
                    width: 40px;
                    height: 14px;
                    background: url('/img/battery/bat-3.png');
                    background-size: 40px;
                }
                .bat-2 {
                    width: 40px;
                    height: 14px;
                    background: url('/img/battery/bat-2.png');
                    background-size: 40px;
                }
                .bat-1 {
                    width: 40px;
                    height: 14px;
                    background: url('/img/battery/bat-1.png');
                    background-size: 40px;
                }
                </style>
                ${accDiv(complex)}
        `
        // показ значка солнца, если питание автономное
        // if(complex.macros.find(macro => macro.macro === '{$POWER}').value === 'BAT'){
        //     return `
        //         <style>
        //             .leaflet-tooltip {
        //                 position: absolute;
        //                 background-color: rgba(0, 0, 0, 0.1) !important;
        //                 border: 0px solid #000;
        //                 color: #000;
        //                 padding: 0px;
        //             }
        //             .sun-tooltip {
        //                 width: 20px;
        //                 height: 20px;
        //                 background: url('/img/sun2.png');
        //                 background-size: 20px;
        //             }
        //         </style>
        //         <div class='sun-tooltip'>
        //         </div>
        //     `
        // }
    }

    return ''
}


export function violationsFixations(complex) {
    if (complex.items.find(item => item.description === 'fixations')) {
        var tmp = complex.items.filter(item => item.description === "fixations")
        const fixations = tmp.length > 0 ? tmp[0].lastvalue : 0;
        tmp = complex.items.filter(item => item.description === "violations")
        const violations = tmp.length > 0 ? tmp[0].lastvalue : 0;
        return { violations, fixations };
    }
    return [ 0, 0 ]
}
export function violationsFixationsCODD(complex) {

    if (complex.items.find(item => item.key_ === 'fixations')) {
        const fixations = complex.items.filter(item => item.key_ === "fixations").length > 0 ? complex.items.filter(item => item.key_ === "fixations")[0].lastvalue : 0;
        const violations = complex.items.filter(item => item.key_ === "violations").length > 0 ? complex.items.filter(item => item.key_ === "violations")[0].lastvalue : 0;
        return { violations, fixations };
    }


    if (complex.items.find(item => item.key_ === 'total-fixations-krechet-mysql.sh[{HOST.IP}, {$MYSQL_LOGIN}, {$MYSQL_PASSWORD}]')) {
        const fixations = complex.items.filter(item => item.key_ === "total-fixations-krechet-mysql.sh[{HOST.IP}, {$MYSQL_LOGIN}, {$MYSQL_PASSWORD}]").length > 0 ? complex.items.filter(item => item.key_ === "total-fixations-krechet-mysql.sh[{HOST.IP}, {$MYSQL_LOGIN}, {$MYSQL_PASSWORD}]")[0].lastvalue : 0;
        const violations = complex.items.filter(item => item.key_ === "total-violations-krechet-mysql.sh[{HOST.IP}, {$MYSQL_LOGIN}, {$MYSQL_PASSWORD}]").length > 0 ? complex.items.filter(item => item.key_ === "total-violations-krechet-mysql.sh[{HOST.IP}, {$MYSQL_LOGIN}, {$MYSQL_PASSWORD}]")[0].lastvalue : 0;
        return { violations, fixations };
    }


    if (complex.items.find(item => item.key_ === 'mssFixation')) {
        const fixations = complex.items.filter(item => item.key_ === "mssFixation").length > 0 ? complex.items.filter(item => item.key_ === "mssFixation")[0].lastvalue : 0;
        const violations = complex.items.filter(item => item.key_ === "mssViolation").length > 0 ? complex.items.filter(item => item.key_ === "mssViolation")[0].lastvalue : 0;
        return { violations, fixations };
    }


    if (complex.items.find(item => item.key_ === 'todays_fixations')) {
        const fixations = complex.items.filter(item => item.key_ === "todays_fixations").length > 0 ? complex.items.filter(item => item.key_ === "todays_fixations")[0].lastvalue : 0;
        const violations = complex.items.filter(item => item.key_ === "todays_violations").length > 0 ? complex.items.filter(item => item.key_ === "todays_violations")[0].lastvalue : 0;
        return { violations, fixations };
    }

    if (complex.items.find(item => item.key_ === 'count_cars')) {
        const fixations = complex.items.filter(item => item.key_ === "count_cars").length > 0 ? complex.items.filter(item => item.key_ === "count_cars")[0].lastvalue : 0;
        const violations = complex.items.filter(item => item.key_ === "todays_violation").length > 0 ? complex.items.filter(item => item.key_ === "todays_violation")[0].lastvalue : 0;
        return { violations, fixations };
    }

    if (complex.items.find(item => item.key_ === 'mssFixation')) {
        const fixations = complex.items.filter(item => item.key_ === "mssFixation").length > 0 ? complex.items.filter(item => item.key_ === "mssFixation")[0].lastvalue : 0;
        const violations = complex.items.filter(item => item.key_ === "mssViolation").length > 0 ? complex.items.filter(item => item.key_ === "mssViolation")[0].lastvalue : 0;
        return { violations, fixations };
    }

    const fixations = complex.items.filter(item => item.key_ === "Ptolemey.todays_passages").length > 0 ? complex.items.filter(item => item.key_ === "Ptolemey.todays_passages")[0].lastvalue : 0;
    const violations = complex.items.filter(item => item.key_ === "Ptolemey.todays_violations").length > 0 ? complex.items.filter(item => item.key_ === "Ptolemey.todays_violations")[0].lastvalue : 0;


    return { violations, fixations };
}

export function getProblems(complex) {
    const result = []
    // console.log(complex)
    for (let trigger of complex.triggers) {
        let key = trigger.triggerid
        
        let problemName = trigger.description;
        let problemDuration = Math.round((new Date()).getTime() / 1000) - trigger.lastchange;
        if (trigger.problem.tags.filter(tag => tag.tag === 'name')[0]) {
            problemName = trigger.problem.tags.filter(tag => tag.tag === 'name')[0].value
            if (problemName.includes('нет фиксаций')) {
                const item = complex.items.find(item => item.key_ === 'last_fixation')  
                problemDuration = parseInt(item.lastvalue)
            } else {
                problemDuration = Math.floor(Date.now() / 1000) - parseInt(trigger.lastchange) + parseInt(trigger.problem.tags.filter(tag => tag.tag === 'base')[0].value);
            }
            }
        let priority = parseInt(trigger.priority, 10)
        let problemColor = '#009549';
        switch (priority) {
            case 0: problemColor = '#595959'; break;
            case 1: problemColor = '#28a1d7'; break;
            case 2: problemColor = '#f5bb3a'; break;
            case 3: problemColor = '#ef9227'; break;
            case 4: problemColor = '#d63a23'; break;
            case 5: problemColor = '#a23337'; break;
            default : problemColor = '#009549'; break;
        }
        result.push([key, complex.name, problemName, uptimeToString(problemDuration), problemColor])
    }
    return result.sort((a, b) => {
        return a[2].localeCompare(b[2])
    })
}


export function getTriggerMaxPriority(complex) {
    let priorities = complex.triggers.map(trigger => parseInt(trigger.priority, 10));
    if (complex.child) {
        complex.child.map(child => {
            priorities = [...priorities, ...child.triggers.map(trigger => parseInt(trigger.priority, 10))]
        })
    }
    let maxPriority = Math.max(...priorities);
    if (!isFinite(maxPriority)) maxPriority = 0;
    // console.log(maxPriority)
    return maxPriority
}

export function getIcon(complex, maxPriority) {
    // console.log(maxPriority)
    // let priorities = complex.triggers.map(trigger => parseInt(trigger.priority, 10));
    // if (complex.child) {
    //     complex.child.map(child => {
    //         priorities = [...priorities, ...child.triggers.map(trigger => parseInt(trigger.priority, 10))]
    //     })
    // }
    // let maxPriority = Math.max(...priorities);
    // if (!isFinite(maxPriority)) maxPriority = 0;
    // const maxPriority = getTriggerMaxPriority(complex)
    // console.log(`maxPriority : ${maxPriority}`);
    let markerColor = 'green';
    switch (maxPriority) {

        case 0: markerColor = 'green'; break;
        case 1: markerColor = 'cyan'; break;
        case 2: markerColor = 'yellow'; break;
        case 3: markerColor = 'orange'; break;
        case 4: markerColor = 'orange-dark'; break;
        case 5: markerColor = 'red'; break;
        default : markerColor = 'green'; break;
    }
    if (complex.status === "1") {
        markerColor = 'black'
    }
    console.log(complex)
    if (complex.hostd === 10917) {
        console.log(complex.triggers)
    }

    let acknowledged = false
    let extra = ''
    let many_violations = false
    complex.triggers.map(trigger =>{
        if (parseInt(trigger.problem.acknowledged) === 1) { 
            acknowledged = true
        }
        if (trigger.problem.tags.find(tag => tag.tag === '220_down')) {
            extra = 'no_power'
        } else if (trigger.problem.tags.find(tag => tag.tag === 'power_off')) {
            extra = 'power_off'
        } else {
            let disabled = trigger.problem.tags.find(tag => tag.tag === 'disabled')
            if (disabled) {
                switch (disabled.value) {
                    case 'all':
                        extra = 'directions_disabled_all'
                        break;
                    default:
                        extra = 'directions_disabled'
                }
            }
        }
        if (trigger.problem.tags.find(tag => tag.tag === 'many_violations')) {
            many_violations = true
        }
        

        
    })
    

    // markerColor = 'violet';
    // иконка с количеством нарушений
    const { violations } = violationsFixations(complex);

    const cclass = complex.tags.find(tag => tag.tag === 'class')
    // console.log(cclass)
    var shape = 'circle'
    switch (cclass.value) {
        case 'crossroad':
            shape = 'square'
            break;
        case 'parking':
            shape = "penta"
            break;
        default:
            shape = 'circle'
    }

    // console.log(extra)
    // many_violations = true
    // extra = 'directions_disabled_all'
   
    var innerIconRight = ''
    var innerIconLeft = ''
    var innerCenter = ''

    if (acknowledged) {
        console.log('acknowledged')
        console.log(complex)
        innerIconRight = `
            <div class='marker-inner-icon-right'>
                <i style="scale:0.9;position: relative;margin-left:0.4px;margin-top:0;padding:0;color: brown;" class="fa fa-xs fa-cog" aria-hidden="true"></i>
            </div>`
    }
    
    switch (extra) {
        case 'no_power':
            innerIconLeft = `
                <div class='marker-inner-icon-left'>
                    <i style="scale:0.8;position: relative;margin-top:0;padding:0;color: brown;" class="fa fa-xs fa-plug" aria-hidden="true"></i>
                </div>`
            break;
        case 'power_off':
            innerIconLeft = `
                    <div class='marker-inner-icon-left'>
                        <i style="scale:0.9;position: relative;margin-top:1px;padding:0;color: brown;" class="fa fa-xs fa-power-off" aria-hidden="true"></i>
                    </div>`
            break;
        case 'directions_disabled_all':
            innerIconLeft = `
                <div class='marker-inner-icon-left' >
                
                    <i style="scale:0.9;position: relative;margin-top:0;padding:0;color: brown;" class="fa fa-xs fa-arrows" aria-hidden="true"></i>
                </div>`
            break;
        case 'directions_disabled':
            innerIconLeft = `
                <div class='marker-inner-icon-left'>
                    <i style="scale:0.9;position: relative;margin-top:0;padding:0;color: brown;" class="fa fa-xs fa-arrows-h" aria-hidden="true"></i>

                </div>`
            break;

    }
    innerCenter = `<div class="marker-inner-center-text">${violations}</div>`
    if (many_violations) {
        innerCenter = `
            <div class="marker-inner-center-icon">
                <i class="fa fa-2x fa-arrow-up fa-pulse" aria-hidden="true"></i>
            </div>`
        markerColor = 'purple'
    }
    var innerIcon = `${innerIconLeft}${innerIconRight}`
    var innerHTML = `
        <div class='marker-inner'>
            ${innerCenter}
            ${innerIcon}
        </div>`
    
    
    let iconMarker = L.ExtraMarkers.icon({
        markerColor,
        shape: shape,
        prefix: 'fa',
        innerHTML: innerHTML
    });

    // switch (type) {
    //     case 'acknowledged':
    //         iconMarker = L.ExtraMarkers.icon({
    //             icon: 'fa-cog',
    //             markerColor,
    //             shape: shape,
    //             prefix: 'fa',
    //             extraClasses: "fa fa-cog fa-spin",
    //             svg: true
    //         });
    //         break;
    //     case 'no_power':
    //         iconMarker = L.ExtraMarkers.icon({
    //             icon: 'fa-plug',
    //             markerColor,
    //             shape: shape,
    //             prefix: 'fa',
    //             extraClasses: "fa fa-plug",
    //         });
    //         break;
    //     case 'power_off':
    //         iconMarker = L.ExtraMarkers.icon({
    //             icon: 'fa-power-off',
    //             markerColor,
    //             shape: shape,
    //             prefix: 'fa',
    //             extraClasses: "fa fa-power-off",
    //         });
    //         break;
    //     case 'many_violations':
    //         iconMarker = L.ExtraMarkers.icon({
    //             icon: 'fa-arrow-up',
    //             markerColor: 'purple',
    //             iconColor: 'white',
    //             shape: shape,
    //             prefix: 'fa fa-2x',
    //             extraClasses: "fa-solid gps_ring"
    //         });
    //         break;
    //     default:
    //         iconMarker = L.ExtraMarkers.icon({
    //             // number : violations,
    //             markerColor,
    //             shape: shape,
    //             prefix: 'fa',
    //             innerHTML: `<div style="padding-top:5px;padding-left:2px">
    //                             <b><div style="color:white;font-size:0.7rem;margin-top:4px;font-family:Consolas">${violations}</div></b>
    //                        <div>`
    //             // innerHTML: `<div style="padding-top:4px">
    //             //                 <b><div style="color:white;font-size:0.7rem;margin-top:5px">?</div></b>
    //             //            <div>`
        
        
    //         });
    // }



    // for (let trigger of complex.triggers) {
    //     if (trigger.problem.acknowledged == 1) {
    //         iconMarker = L.ExtraMarkers.icon({
    //             icon: 'fa-cog',
    //             markerColor,
    //             // shape: 'star',
    //             shape: 'square',
    //             prefix: 'fa',
    //             extraClasses: "fa fa-cog fa-spin",
    //             svg: true
    //         });
    //         break;
    //     }
    // }


    return iconMarker;
}

export function getIconDeactivated() {
    let markerColor = 'black'
    let iconMarker = L.ExtraMarkers.icon({
        icon: 'fa-close',
        markerColor,
        shape: 'circle',
        prefix: 'fa',
        // extraClasses: "fa-power-off",
    });
    return iconMarker;
}

export function getIconMarkerCluster(mcount, maxPriority) {
    let markerColor = '#008C3A';
    switch (maxPriority) {

        case 0: markerColor = '#008C3A'; break;
        case 1: markerColor = '#21A2DA'; break;
        case 2: markerColor = '#F4B62A'; break;
        case 3: markerColor = '#F28A18'; break;
        case 4: markerColor = '#D32D19'; break;
        case 5: markerColor = '#9C262A'; break;
        default : markerColor = '#008C3A'; break;
    }
    const icon = L.divIcon({
        className: 'custom-div-icon',
        html: `<div style='background-color:${markerColor};' class='marker-pin'></div>
        <i class='material-icons'>${mcount}</i>`,
        iconSize: [35, 45],
        iconAnchor: [15, 42]
    });
    return icon
    
}
export function getIconBattery(bp) {
    let icon = { 'classname' :'fa fa-solid fa-rotate-270 fa-battery-full','color': '#009549'}
    if (bp < 80) {
        icon.classname = 'fa fa-solid fa-rotate-270 fa-battery-three-quarters'
        icon.color = '#009549'
    }
    if (bp < 60) {
        icon.classname = 'fa fa-solid fa-rotate-270 fa-battery-half'
        icon.color = '#ef9227'
        // icon.color = '#f5bb3a'
    }
    if (bp < 40) {
        icon.classname = 'fa fa-solid fa-rotate-270 fa-battery-quarter'
        icon.color = '#ef9227'
    }
    if (bp < 20) {
        icon.classname = 'fa fa-solid fa-rotate-270 fa-battery-empty'
        icon.color = '#d63a23'
    }


    return <div style={{ color:icon.color, opacity: '1', scale: '1.4 1.1', padding: '5px 2px 5px' }} className={icon.classname}></div>
}

export function getPowerOff() {
    return <div style={{ color: 'black', opacity: '1', scale: '1.4 1.4', padding: '5px 5px 1px 4px' }} className='fa fa-power-off'></div>
}

export function getPowerPlug() {
    return <div style={{ color: 'brown', opacity: '1', scale: '1.1 1.1', padding: '5px 5px 1px 4px' }} className='fa fa-solid fa-plug'></div>
}

export function getLogo() {
    let icon = { 'classname' :'fa fa-solid fa-code','color': 'green'}

    return <span style={{ color:icon.color, opacity: '1', scale: '1.4 1.4', padding: '10px' }} className={icon.classname}></span>


}

export function getIconOVN(complex) {
    let priorities = complex.triggers.map(trigger => parseInt(trigger.priority, 10));
    let maxPriority = Math.max(...priorities);
    if (!isFinite(maxPriority)) maxPriority = 0;
    // console.log(`maxPriority : ${maxPriority}`);
    let markerColor = 'green';
    switch (maxPriority) {

        case 0: markerColor = 'green'; break;
        case 1: markerColor = 'cyan'; break;
        case 2: markerColor = 'yellow'; break;
        case 3: markerColor = 'orange'; break;
        case 4: markerColor = 'orange-dark'; break;
        case 5: markerColor = 'red'; break;
        default : markerColor = 'green'; break;
    }
    if (complex.status === "1") {
        markerColor = 'black'
    }


    let iconMarker = L.ExtraMarkers.icon({
        // number : violations,
        markerColor,
        shape: 'circle',
        prefix: 'fa',
        icon : 'fa-camera',
        // innerHTML: `<div style="padding-top:4px">
        //                 <b><div style="color:white;font-size:0.7rem;margin-top:5px">${violations}</div></b>
        //            <div>`


    });

    return iconMarker;
}




export function getIconPP(complex) {
    let priorities = complex.triggers.map(trigger => parseInt(trigger.priority, 10));
    let maxPriority = Math.max(...priorities);
    if (!isFinite(maxPriority)) maxPriority = 0;
    // console.log(`maxPriority : ${maxPriority}`);
    let markerColor = 'green';
    switch (maxPriority) {

        case 0: markerColor = 'green'; break;
        case 1: markerColor = 'cyan'; break;
        case 2: markerColor = 'yellow'; break;
        case 3: markerColor = 'orange'; break;
        case 4: markerColor = 'orange-dark'; break;
        case 5: markerColor = 'red'; break;
        default : markerColor = 'green'; break;
    }
    if (complex.status === "1") {
        markerColor = 'black'
    }


    let iconMarker = L.ExtraMarkers.icon({
        // number : violations,
        markerColor,
        shape: 'circle',
        prefix: 'fa',
        icon : 'fa-navicon',
        // innerHTML: `<div style="padding-top:4px">
        //                 <b><div style="color:white;font-size:0.7rem;margin-top:5px">${violations}</div></b>
        //            <div>`


    });


    return iconMarker;
}






















