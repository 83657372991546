import { $authHost } from ".";

export async function getState() {
    const { data } = await $authHost.get('/api/state')
    return data
}

export async function execScript(hostid, scriptid) {
    try {
        console.log(hostid, scriptid)
        const { data } = await $authHost.post('/api/script/exec', {hostid, scriptid})
        return data

    } catch(e) {
        console.log(e)
    }
    
    
}
