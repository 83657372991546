import React, { useContext } from "react";
import { Marker, useMap } from "react-leaflet";
import { getIcon, getTriggerMaxPriority } from "../helper";
import "leaflet/dist/leaflet.css";
import "leaflet-extra-markers";
import "font-awesome/css/font-awesome.css";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import MarkerPopup from "./MarkerPopup";
import MarkerTooltip from "./MarkerTooltip";

import { ComplexesFilterContext } from "../../../context";

export const MarkersCrossroad = props => {
    const { complexesFilterPayload } = useContext(ComplexesFilterContext)
    if (!props.hosts) {
        return <></>
    }

    const markers = props.hosts.map(complex => {
        
        const info = complex.child.filter(child => child.triggers.length !== 0)

        if (
            complex.inventory.location_lat === "" &&
            complex.inventory.location_lon === ""
        ) {
            return null;
        }
        var triggers = true
        if (complexesFilterPayload.severity && complexesFilterPayload.severity > 0) {
            triggers = (complex.triggers.find((t) => parseInt(t.priority, 10) >= complexesFilterPayload.severity)) ? true : false
            if (!triggers) {
                // complex.child.map(c => {
                //     triggers = (c.triggers.find((t) => parseInt(t.priority, 10) >= complexesFilterPayload.severity)) ? true : false
                // })
                if (complex.child.find(c => (c.triggers.find(t => parseInt(t.priority, 10) >= complexesFilterPayload.severity)))) {
                    triggers = true
                }
            }
        }
        if (!triggers) {
            return null
        }

        const triggetMaxPriority = getTriggerMaxPriority(complex)
        var zIndexAdd = 0
        complex.triggers.map(trigger =>{
            if (trigger.problem.tags.find(tag => tag.tag === 'many_violations')) {
                zIndexAdd = 300;
            }
        })
        
        return (
            <Marker
                position={[
                    complex.inventory.location_lat,
                    complex.inventory.location_lon,
                ]}
                icon={getIcon(complex, triggetMaxPriority)}
                key={complex.hostid}
                data={complex.hostid}
                zIndexOffset={triggetMaxPriority * 100 + zIndexAdd}
 
            >
                <MarkerPopup complex={complex} key={`popup_${complex.hostid}`} />
                {
                    info.length > 0 ?
                    <MarkerTooltip complex={complex} key={`tooltip_${complex.hostid}`} />
                        :
                        null

                }
            </Marker>
        );
    });
    return markers;
}

