import { Popup } from "react-leaflet"
import { Button } from "react-bootstrap";
import { violationsFixations } from "../helper";
import React from "react"
import { getProblems } from "../helper";
import { useContext } from "react";
import { CurrentComplexContext, UserContext } from "../../../context";
import DropdownLinks from "./DropdownLinks";
import DropdownScripts from "./DropdownScripts";
import '../../../styles/MarkerPopup.css'

const MarkerPopup = props => {
    const { violations, fixations } = violationsFixations(props.complex);
    const { setCurrentComplexPayload } = useContext(CurrentComplexContext)
    const { userPayload } = useContext(UserContext)
    const { roles } = userPayload
    const problems = getProblems(props.complex)
    const infoClick = () => {
        setCurrentComplexPayload({ modal: true, class: 'speed', complex: props.complex })
    }
    return (
        <Popup maxWidth={400}>

            <div className='popup_header'>
                <span style={{ fontWeight: 600 }}>{props.complex.name} </span>
            </div>
            <hr style={{ margin: '5px' }} />

            <div className='popup_body'>
                <span className="popup_body_badge popup_body_badge_coordinates">{props.complex.inventory.location_lat}, {props.complex.inventory.location_lon}</span>
                <span className="popup_body_badge popup_body_badge_date">{(new Date()).toLocaleString('ru')}</span>
                
                <br />
            </div>
            <hr style={{ margin: '5px' }} />

            <div className='popup_body'>

                <span className="popup_body_badge popup_body_badge_fixations">Проездов</span>
                <span className="popup_body_badge popup_body_badge_fixations">{fixations}</span>
                <span className="popup_body_badge popup_body_badge_violations">{violations}</span> 
                <span className="popup_body_badge popup_body_badge_violations">Нарушений</span>
                <br />
            </div>
            <hr style={{ margin: '5px' }} />

            {
                problems.length === 0
                    ?
                    <div className='popup_body'>
                        <span className="popup_body_badge popup_body_badge_alerts_good">Работа в штатном режиме</span>
                    </div>
                    : problems.map(element => {
                        return (
                            <div className='popup_body' key={element[0]}>
                                {/* <span className="popup_body_badge popup_body_badge_alerts_host">{element[1].split('/')[1]}</span> */}
                                <span className="popup_body_badge popup_body_badge_alerts_problem" style={{ color: element[4] }}>{element[2]}: {element[3]}</span>
                                {/* <span className="popup_body_badge popup_body_badge_alerts_duration">{element[3]}</span> */}
                            </div>

                        )
                    })
            }
            <hr style={{ margin: '5px' }} />
            <div className='popup_footer'>
                {roles && !(roles.includes('operator')) ?
                    <div style={{ display: 'flex'}}>
                        <DropdownLinks  complex={props.complex}/>
                        <DropdownScripts complex={props.complex}/>
                    </div>
                    : null
                }


                <Button size="sm" variant="primary" onClick={infoClick}> Подробно </Button>
            </div>

            {/* <div style={{ fontSize: "12px" }}>
                <span style={{ fontWeight: 600 }}>{props.complex.name} </span>
                <br />
                <p />
                [{props.complex.inventory.location_lat}, {props.complex.inventory.location_lon}]
            </div>
            <div>
                <p />
                {(new Date()).toLocaleString('ru')}
            </div>
            <div style={{ fontSize: "12px" }}>
                <br />
                <span style={{ color: "green" }}>Проездов в течение дня: {fixations}</span>
                <br />
                <span style={{ color: "red" }}>Нарушений в течение дня: {violations} </span>
                <br />
            </div>
            <br />
            <div style={{ fontSize: "12px" }}>
                <ul>
                    {
                        problems.length === 0
                            ?
                            <li><span style={{ color: "green" }} key={0}>Работа в штатном режиме</span></li>
                            : problems.map(element => {
                                return (
                                    <li key={`${element.key_}_${props.complex.hostid}`}><span style={{ color: "red" }}> {element[0]} : {element[1]}</span></li>
                                )
                            })
                    }
                </ul>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "right" }}>
                <Button size="sm" variant="outline-primary" onClick={infoClick}>Подробно</Button>
            </div> */}
        </Popup>
    )
}


export default MarkerPopup;
