import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { UserContext } from "../context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getLogo } from "./markers/helper";
import '../styles/Navbar.css'

const NavBar = () => {


  const { userPayload, setUserPayload } = useContext(UserContext)
  const { isAuth } = userPayload
  const navigate = useNavigate()

  const logout = () => {
    localStorage.clear()
    setUserPayload({ user: null, isAdmin: null, isAuth: null })
    navigate('/login', { replace: true })
  }

  return (
    <Navbar expand="lg" bg="primary" variant="dark bg-dark gradient" style={{ fontFamily: 'Consolas'}} >
      <Container fluid={true}>
        <Navbar.Brand style={{ justifyContent: 'center', textAlign: 'center'}}>
          <Nav.Link as={Link} to='map'>{getLogo()} Мониторинг</Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <Nav >
            {/* {isAdmin && isAdmin ?
              <Nav.Link as={Link} to='adm'>Пользователи</Nav.Link>
            :
              null
            } */}
            {isAuth ?
              <Nav.Link as={Link} onClick={() => logout()}>Выход</Nav.Link>
              :
              <Nav.Link as={Link} to="login">Авторизация</Nav.Link>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>




  );
}

export default NavBar;
