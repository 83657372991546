import { Popup } from "react-leaflet"
import React from "react"


const MarkerPopup = props => {
    return (
        <Popup maxWidth={500}>
            <div className='popup_header'>
                <span style={{ fontWeight: 600 }}>{props.complex.name} </span>
            </div>
            <hr style={{ margin: '5px' }} />
            <div className='popup_body'>
                <span className="popup_body_badge popup_body_badge_coordinates">{props.complex.inventory.location_lat}, {props.complex.inventory.location_lon}</span>
                <span className="popup_body_badge popup_body_badge_date">{(new Date()).toLocaleString('ru')}</span>
                
                <br />
            </div>
            <hr style={{ margin: '5px' }} />
            {/* <div style={{ fontSize: "12px" }}>
                <span style={{ fontWeight: 600 }}>{props.complex.name} </span>
                <br />
                <p />
                [{props.complex.inventory.location_lat}, {props.complex.inventory.location_lon}]
            </div>
            <div>
                <p />
                {(new Date()).toLocaleString('ru')}
            </div>
            <div style={{ fontSize: "12px" }}>
                <br />
                <span style={{ color: "green" }}>Проездов в течение дня: {fixations}</span>
                <br />
                <span style={{ color: "red" }}>Нарушений в течение дня: {violations} </span>
                <br />
            </div>
            <br />
            <div style={{ fontSize: "12px" }}>
                <ul>
                    {
                        problems.length === 0
                            ?
                            <li><span style={{ color: "green" }} key={0}>Работа в штатном режиме</span></li>
                            : problems.map(element => {
                                return (
                                    <li key={`${element.key_}_${props.complex.hostid}`}><span style={{ color: "red" }}> {element[0]} : {element[1]}</span></li>
                                )
                            })
                    }
                </ul>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "right" }}>
                <Button size="sm" variant="outline-primary" onClick={infoClick}>Подробно</Button>
            </div> */}
        </Popup>
    )
}


export default MarkerPopup;
