import React from "react";
import { Marker } from "react-leaflet";
import { getIconDeactivated } from "../helper";
import "leaflet/dist/leaflet.css";
import "leaflet-extra-markers";
import "font-awesome/css/font-awesome.css";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import MarkerPopup from "./MarkerPopup";
import MarkerTooltip from "./MarkerTooltip";


export const MarkersDeactivated = props => {
    if (!props.hosts) {
        return <></>
    }
    const markers = props.hosts.map(complex => {
        if (
            complex.inventory.location_lat === "" &&
            complex.inventory.location_lon === ""
        ) {
            return null;
        }
        return (
            <Marker
                position={[
                    complex.inventory.location_lat,
                    complex.inventory.location_lon,
                ]}
                icon={getIconDeactivated(complex)}
                key={complex.hostid}
                data={complex.hostid}
            >
                <MarkerPopup complex={complex} key={`popup_${complex.hostid}`} />
                {
                    complex.macros.find(item => item.macro === '{$POWER}')?.value === 'BAT' ?
                    <MarkerTooltip complex={complex} key={`tooltip_${complex.hostid}`} />
                        :
                        null

                }
            </Marker>
        );
    });
    return markers;
}

