import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Accordion, AccordionBody, AccordionItem, Card } from 'react-bootstrap';
import { InputGroup, Form } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { ComplexesContext } from '../../context'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { violationsFixations } from '../markers/helper';
import "../../styles/ModalSearch.css"
import "../../styles/Modal.css"


const ModalSearch = props => {
    const { complexesPayload } = useContext(ComplexesContext)
    const { hosts } = complexesPayload
    const [hostsC, setHostsC] = useState(hosts.crossroad.sort((a, b) => {
        return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
    }))
    const [hostsS, setHostsS] = useState(hosts.speed.sort((a, b) => {
        return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
    }))
    const [hostsP, setHostsP] = useState(hosts.parking.sort((a, b) => {
        return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
    }))


    const filter = (text) => {
        if (text === "") { text = ' ' }
        setHostsC(hosts.crossroad.sort((a, b) => {
            return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
        })
        // .sort((hostA, hostB) => hostA.name.split['.'][0] - hostB.name.split['.'][0])
        // .sort((a,b) => (+a.name.split('.')[0]) - (+b.name.split('.')[0]))
        .filter(host => host.name.toLowerCase()
            .includes(text.toLowerCase())))
        
        setHostsS(hosts.speed.sort((a, b) => {
            return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
        })
        // .sort((hostA, hostB) => hostA.name.split['.'][0] - hostB.name.split['.'][0])
        // .sort((a,b) => (+a.name.split('.')[0]) - (+b.name.split('.')[0]))
        .filter(host => host.name.toLowerCase()
            .includes(text.toLowerCase())))

        setHostsP(hosts.parking.sort((a, b) => {
            return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
        })
        // .sort((hostA, hostB) => hostA.name.split['.'][0] - hostB.name.split['.'][0])
        // .sort((a,b) => (+a.name.split('.')[0]) - (+b.name.split('.')[0]))
        .filter(host => host.name.toLowerCase()
            .includes(text.toLowerCase())))
    }

    return (
        <Modal show={props.visible}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onEscapeKeyDown={() => props.close()}
            scrollable
            onShow={() => filter(' ')}
            className='.search_modal'
        >
            <Modal.Header className='modal_search_header'>
                <Modal.Title className='modal_search_header_title'>Поиск</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal_search_body'>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1" >
                        <FontAwesomeIcon icon={icon({ name: 'search' })} />
                    </InputGroup.Text>
                    <Form.Control
                        placeholder="поиск..."
                        aria-label="поиск..."
                        aria-describedby="basic-addon1"
                        onChange={e => filter(e.target.value)}
                        style={{ fontFamily: 'Consolas'}}
                    />
                </InputGroup>

                <Accordion>
                    {
                        hostsC.length > 0 ?
                        <AccordionItem>
                            <Accordion.Header as={'h1'}>Перекрестки</Accordion.Header>
                            <AccordionBody>
                                {
                                    hostsC.map(host => {
                                        const current = host

                                        return <div key={host.hostid}>
                                            <Button variant="primary" size='sm' onClick={() => {props.select(current)}}>
                                                <FontAwesomeIcon icon={icon({ name: 'location-dot' })} />
                                            </Button>
                                            <span className='modal_search_accordion_body'>{host.name}</span>
                                        </div>
                                    })
                                }
                            </AccordionBody>
                        </AccordionItem>
                        : null
                    }
                    {
                        hostsS.length > 0 ?
                        <AccordionItem>
                            <Accordion.Header key={1} as={'h1'}>Скоростные</Accordion.Header>
                            <AccordionBody>
                                {
                                    hostsS.map(host => {
                                        const current = host

                                        return <div key={host.hostid}>
                                            <Button variant="primary" size='sm' onClick={() => {props.select(current)}}>
                                                <FontAwesomeIcon icon={icon({ name: 'location-dot' })} />
                                            </Button>
                                            <span className='modal_search_accordion_body'>{host.name}</span>
                                        </div>
                                    })
                                }
                            </AccordionBody>
                        </AccordionItem>
                        : null
                    }
                    {
                        hostsP.length > 0 ?
                        <AccordionItem>
                            <Accordion.Header key={2} as={'h1'}>Парковка</Accordion.Header>
                            <AccordionBody>
                                {
                                    hostsP.map(host => {
                                        const current = host

                                        return <div key={host.hostid}>
                                            <Button variant="primary" size='sm' onClick={() => {props.select(current)}}>
                                                <FontAwesomeIcon icon={icon({ name: 'location-dot' })} />
                                            </Button>
                                            <span className='modal_search_accordion_body'>{host.name}</span>
                                        </div>
                                    })
                                }
                            </AccordionBody>
                        </AccordionItem>
                        : null
                    }
                </Accordion>

                {/* <div style={{ overflowY: "auto", height: "calc(100vh - 250px)", fontSize: "14px" }}>
                    {
                        hosts.map(host => {
                            const current = host
                            const { violations, fixations } = violationsFixations(host)

                            return (
                                <Card className='mb-2' key={host.hostid}>
                                    <Card.Body style={{ display: "flex" }}>
                                        <Button variant="primary" size='sm' onClick={
                                            () => {
                                                props.select(current)
                                            }
                                        }>
                                            <FontAwesomeIcon icon={icon({ name: 'location-dot' })} />
                                        </Button>
                                        <Card.Text style={{ paddingTop: "3px", paddingLeft: "10px"}}>
                                        {host.name}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            )
                        })
                    }

                </div> */}
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="primary" onClick={() => {
                    props.close()
                }}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalSearch
