import React, { useContext } from "react";
import { Marker } from "react-leaflet";
import { getIcon, getTriggerMaxPriority } from "../helper";
import "leaflet/dist/leaflet.css";
import "leaflet-extra-markers";
import "font-awesome/css/font-awesome.css";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import MarkerPopup from "./MarkerPopup";
import MarkerTooltip from "./MarkerTooltip";

import { ComplexesFilterContext } from "../../../context";

export const MarkersParking = props => {
    const { complexesFilterPayload } = useContext(ComplexesFilterContext)
    if (!props.hosts) {
        return <></>
    }
    const markers = props.hosts.map(complex => {
        if (
            complex.inventory.location_lat === "" &&
            complex.inventory.location_lon === ""
        ) {
            return null;
        }

        if (complexesFilterPayload.severity && complexesFilterPayload.severity > 0) {
            const triggers = (complex.triggers.find((t) => parseInt(t.priority, 10) >= complexesFilterPayload.severity)) ? true : false
            if (!triggers) { 
                return null
            }
        }
        
        const triggetMaxPriority = getTriggerMaxPriority(complex)
        return (
            <Marker
                position={[
                    complex.inventory.location_lat,
                    complex.inventory.location_lon,
                ]}
                icon={getIcon(complex, triggetMaxPriority)}
                key={complex.hostid}
                data={complex.hostid}
                
            >
                <MarkerPopup complex={complex} key={`popup_${complex.hostid}`} />
                {
                    complex.macros.find(item => item.macro === '{$POWER}')?.value === 'BAT' ?
                    <MarkerTooltip complex={complex} key={`tooltip_${complex.hostid}`} />
                        :
                        null

                }
            </Marker>
        );
    });
    return markers;
}

