import { $authHost, $host } from ".";
import  { jwtDecode } from "jwt-decode";

export const signin = async (login, password) => {
    const response = await $host.post('/api/auth/login', { login, password })
    const data = response.data
    localStorage.setItem('token', data.accessToken)
    return jwtDecode(data.accessToken)
}

export const check = async () => {
    const { data } = await $authHost.get('/api/auth/check')
    localStorage.setItem('token', data.accessToken)
    return jwtDecode(data.accessToken)
}

export const getUsers = async () => {
    try {
        const data = await $authHost.get('/api/user/all')
        return data
    } catch(e) {
        console.log(e)
    }
    
}
export const getRoles = async () => {
    try{ 
        const data = await $authHost.get('/api/role/all')
        return data
    } catch(e) {
        console.log(e)
    }
}
export const addUser = async (user) => {
    try{ 
        const data = await $authHost.post('/api/user/create', user)
        return data
    } catch(e) {
        console.log(e)
    }
}
export const addRole = async (user) => {
    try{ 
        const data = await $authHost.post('/api/role/create', user)
        return data
    } catch(e) {
        console.log(e)
    }
}
export const editUser = async (user) => {
    try {
        const data = await $authHost.post('/api/user/update', user)
        return data
    } catch(e) {
        console.log(e)
    }
}

export const deleteUser = async (user) => {
    try {
        const data = await $authHost.post('/api/user/delete', user)
        return data
    } catch(e) {
        console.log(e)
    }
}

