import React, { useEffect, useRef } from "react";
import { LayersControl, LayerGroup, TileLayer } from "react-leaflet";
import { MarkersCrossroad } from "../markers/crossroad/Markers";
import { MarkersSpeed } from "../markers/speed/Markers";
import { MarkersParking } from "../markers/parking/Markers";
import { MarkersDeactivated } from "../markers/deactivated/Markers";

import { ComplexesContext } from "../../context";
import { useContext } from "react";
import { getIconMarkerCluster } from "../markers/helper";
const { BaseLayer } = LayersControl

const baseLayers = {
    twogis: "https://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1.1",
    osm: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
    arc: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
}

const ControlLayer = (props) => {
    const { complexesPayload } = useContext(ComplexesContext)
    const { hosts } = complexesPayload

    useEffect(() => {
        if(localStorage.getItem('base_layer') === null) {
            localStorage.setItem('base_layer', 1)
        }
        if(localStorage.getItem('layer_crossroad') === null) {
            localStorage.setItem('layer_crossroad', 1)
        }
        if(localStorage.getItem('layer_speed') === null) {
            localStorage.setItem('layer_speed', 1)
        }
        if(localStorage.getItem('layer_parking') === null) {
            localStorage.setItem('layer_parking', 1)
        }
        if(localStorage.getItem('layer_deact') === null) {
            localStorage.setItem('layer_deact', 0)
        }

    }, [])


  
    return (
        
        <LayersControl position="topright" collapsed={true}>
            <BaseLayer checked={localStorage.getItem('base_layer') === '1' ? true : false} name="OSM">
                <TileLayer url={baseLayers.osm} eventHandlers={{
                    add: (e) => {localStorage.setItem('base_layer', 1)}
                }}/>
            </BaseLayer>
            <BaseLayer checked={localStorage.getItem('base_layer') === '2' ? true : false} name="2GIS" >
                <TileLayer url={baseLayers.twogis} eventHandlers={{
                    add: (e) => {localStorage.setItem('base_layer', 2)}
                }}/>
            </BaseLayer>
            <BaseLayer checked={localStorage.getItem('base_layer') === '3' ? true : false} name="ArcGIS">
                <TileLayer url={baseLayers.arc} eventHandlers={{
                    add: (e) => {localStorage.setItem('base_layer', 3)}
                }}/>
            </BaseLayer>

            

            
            <LayersControl.Overlay name="Перекрестки" checked={localStorage.getItem('layer_crossroad') === '1' ? true : false}>
                <LayerGroup eventHandlers={{
                    add: (e) => {localStorage.setItem('layer_crossroad', 1)},
                    remove: (e) => {localStorage.setItem('layer_crossroad', 0)}
                }}>
                    {/* <MarkerMuster maxClusterRadius={4} animate > */}
                    <MarkersCrossroad hosts={hosts.crossroad} />
                    {/* </MarkerMuster> */}
                    
                </LayerGroup>
            </LayersControl.Overlay>

           
            <LayersControl.Overlay name="Скоростные" checked={localStorage.getItem('layer_speed') === '1' ? true : false}>
                <LayerGroup eventHandlers={{
                    add: (e) => {localStorage.setItem('layer_speed', 1)},
                    remove: (e) => {localStorage.setItem('layer_speed', 0)}
                }}>
                    {/* <MarkerMuster maxClusterRadius={15} iconCreateFunction={createClusterCustomIconForSpeed} animate> */}
                        <MarkersSpeed hosts={hosts.speed} />
                    {/* </MarkerMuster> */}
                    
                    
                </LayerGroup>
            </LayersControl.Overlay>
            
            <LayersControl.Overlay name="Парковка" checked={localStorage.getItem('layer_parking') === '1' ? true : false}>
                <LayerGroup eventHandlers={{
                    add: (e) => {localStorage.setItem('layer_parking', 1)},
                    remove: (e) => {localStorage.setItem('layer_parking', 0)}
                }}>
                    {/* <MarkerMuster maxClusterRadius={1} animate > */}
                    <MarkersParking hosts={hosts.parking} />
                    {/* </MarkerMuster> */}
                    
                </LayerGroup>
            </LayersControl.Overlay>

            <LayersControl.Overlay name="Деактивированные" checked={localStorage.getItem('layer_deact') === '1' ? true : false}>
                <LayerGroup eventHandlers={{
                    add: (e) => {localStorage.setItem('layer_deact', 1)},
                    remove: (e) => {localStorage.setItem('layer_deact', 0)}
                }}>
                    <MarkersDeactivated hosts={hosts.deactivated} />
                </LayerGroup>
            </LayersControl.Overlay>
            
        </LayersControl>
    );
};

export default ControlLayer;
